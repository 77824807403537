import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ApiUrl } from '../../enums/api-url.enum';
import { Observable, finalize, shareReplay, tap } from 'rxjs';
import { UserInfo } from './user-info.model';
import { WaitingService } from 'src/app/shared/services/waiting.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo = signal<UserInfo>(undefined);
  private readonly httpClient = inject(HttpClient);
  private readonly waitingService = inject(WaitingService);
  private userProfileRequest: Observable<UserInfo> | null = null;

  setUserProfile(): Observable<UserInfo> {
    if (this.userProfileRequest) {
      return this.userProfileRequest;
    }

    this.waitingService.show();
    this.userProfileRequest = this.httpClient.get<UserInfo>(ApiUrl.SHARED.USER_PROFILE).pipe(
      tap((data: UserInfo) => this.userInfo.set(data)),
      finalize(() => {
        this.waitingService.hide();
        this.userProfileRequest = null;
      }),
      shareReplay(1)
    );

    return this.userProfileRequest;
  }
}
